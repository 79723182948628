<template>
  <div class="home">
    <div id="Content">
      <div class="shadow"></div>
      <div class="content-inner">
        <div class="content-path">
          <breadcrumbs :crumbs="breadcrumbs" />
        </div>
        <div class="canvas-container">
          <canvas id="stock-ticket"></canvas>
          <canvas id="other-ticket"></canvas>
        </div>
        <div class="canvas-selector">
          <diyobo-input
            type="dropdown"
            placeholder="No Ticket Selected"
            label="Select Ticket Type"
            :options="ticketTypes"
            v-model="selected"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.home {
  #Content {
    .content-inner {
      display: flex;
      flex-wrap: wrap;

      .canvas-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;

        canvas {
          background-color: white;
          border: solid black 1px;
        }

        #stock-ticket {
          display: none;
          width: 600px;
          height: 200px;
          border: solid red 1px;
        }

        #other-ticket {
          display: none;
          width: 528px;
          height: 408px;
          border: solid blue 1px;
        }
      }

      .canvas-selector {
        margin-top: 25px;
        display: flex;
        width: 250px;
      }
    }
    // end content-inner
  }
  // end Content
}
</style>

<script>
import DiyoboInput from "@/components/DiyoboInput.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";

export default {
  name: "ticket-setup",
  components: {
    DiyoboInput,
    DiyoboButton,
    Breadcrumbs
  },
  props: {
    url: String
  },
  head() {
    return {
      title: "Ticket Setup"
    };
  },
  data() {
    return {
      breadcrumbs: [
        ["Dashboard", "/dashboard"],
        ["Ticket Setup", null]
      ],
      ticketTypes: [
        { id: "stock-ticket", label: "Stock Ticket", value: "Stock Ticket" },
        { id: "other-ticket", label: '8.5" by 11"', value: '8.5" by 11"' }
      ],
      selected: ""
    };
  },
  watch: {
    selected: function() {
      this.canvasToggle(this.selected);
    }
  },
  methods: {
    canvasToggle(string) {
      var stock = document.getElementById("stock-ticket");
      var other = document.getElementById("other-ticket");

      for (let x = 0; x < this.ticketTypes.length; x++) {
        if (this.ticketTypes[x].label == string) {
          document.getElementById(this.ticketTypes[x].id).style.display =
            "block";
        } else {
          document.getElementById(this.ticketTypes[x].id).style.display =
            "none";
        }
      }

      return;
    },
    canvasStock(canvas) {
      let canvasCTX = canvas.getContext("2d");
      let scale = 2;

      canvas.width = 600 * scale;
      canvas.height = 200 * scale;
      canvasCTX.font = "30px Arial";
      canvasCTX.fillText("Hello World", 10, 50);
    },
    canvasOther(canvas) {
      let canvasCTX = canvas.getContext("2d");
      let scale = 2;

      canvas.width = 528 * scale;
      canvas.height = 408 * scale;
      canvasCTX.font = "30px Arial";
      canvasCTX.fillText("Hello World", 10, 50);
    }
  },
  created() {
    this.$store.commit("setTitle", "Ticket Setup");
  },
  beforeUpdate() {
    var stock = document.getElementById("stock-ticket");
    var other = document.getElementById("other-ticket");

    this.canvasStock(stock);
    this.canvasOther(other);
  }
};
</script>
